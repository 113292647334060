<template>
<div class="card-body">

  <div class="row">
    <nav class="nav nav-pills nav-fill mt-2">
        <router-link to="/admin/users" class="nav-item nav-link" >{{$t('menu.users')}}</router-link>
        <router-link to="/admin/companies" class="nav-item nav-link active">{{$t('menu.companies')}}</router-link>
    </nav>
  </div>

  <div class="row">

    <div class="col-md-12">
      <h5 class="my-3">{{$t('menu.companies')}}</h5>
      <CompaniesTable :companies="waitingAccounts"></CompaniesTable>

      <h5>zamitnute</h5>
      <CompaniesTable :companies="rejectedAccounts"></CompaniesTable>
    </div>
  </div>
</div>
</template>

<script>

import CompaniesTable from "@/views/admin/CompaniesTable";
const axios = require("axios");
const moment = require('moment');
require('moment/locale/cs');
moment.locale('cs');

export default {
  name: "Companies",
  components: {CompaniesTable},
  mounted() {
    this.refreshAccounts()
  },
  data(){
    return{
      waitingAccounts: [],
      rejectedAccounts:[]
    }
  },
  methods:{
    acceptCompany(a){
        axios.get(
                process.env.VUE_APP_API_ROOT_URL + "/companies/accept/"+a.id)
            .then(result => {
                if (result.status == 200){
                  this.refreshAccounts()
                }
            })
    },
    rejectCompany(a){
      axios.get(
                process.env.VUE_APP_API_ROOT_URL + "/companies/reject/"+a.id)
            .then(result => {
              if (result.status == 200){
                  this.refreshAccounts()
                }
            })

    },
    refreshAccounts(){
            axios.get(
                process.env.VUE_APP_API_ROOT_URL + "/companies/waiting")
            .then(result => {
                this.waitingAccounts = result.data.companies
            })

            axios.get(
              process.env.VUE_APP_API_ROOT_URL + "/companies/rejected")
              .then(result => {
                this.rejectedAccounts = result.data.companies
              })

      }
  }
}
</script>

<style scoped>

</style>
